import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Button, Spinner, Stack } from "react-bootstrap"
import { Lyrics } from "./model/Lyrics"
import { LyricsComponent } from "./LyricsComponent"
import { Song } from "./model/Song"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { SongRepositoryContext } from "./SongRepositoryContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faClose, faCloudDownloadAlt, faDownload, faEdit, faHdd, faPause, faPlay, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Language } from "./model/Language"
import { LanguageSelector } from "./editor/Parser"
import "./SingAlong.css"
import { AudioPlayer } from "./AudioPlayer"

const SingAlong: React.FC = () => {
    const songRepository = useContext(SongRepositoryContext)
    const navigate = useNavigate()
    const location = useLocation()

    const wakeLockRef = useRef<WakeLockSentinel | null>(null)

    const params = useParams()
    const songId = params.songId
    const language: Language | undefined = params.language ? Language.parse(params.language) : undefined
    const leadTimeMs: number = 500

    const [data, setData] = useState<Song | undefined>(undefined)
    const [time, setTime] = useState(0)
    const [playing, setPlaying] = useState(false)
    const [playbackBuffer] = useState(2)
    const [audioBlobUrl, setAudioBlobUrl] = useState<string | undefined>(undefined)

    const [audioLoading, setAudioLoading] = useState(false)
    const [audioCaching, setCaching] = useState(false)

    const audioRef: RefObject<HTMLAudioElement> = useRef<HTMLAudioElement>(null)

    const loading = data === undefined
    const audioLoaded = audioBlobUrl !== undefined
    const lyrics: Lyrics | undefined = (data && language) ? data.lyrics.get(language) : undefined
    const availableLanguages = data ? Array.from(data.lyrics.keys()) : []

    const loadAudio = useCallback(async () => {
        if (data?.audio) {
            setAudioLoading(true)
            const blob = await songRepository.getAudioFile(data.audio.fileId)
            if (blob) {
                setAudioBlobUrl(URL.createObjectURL(blob))
                setAudioLoading(false)
            } else {
                console.error("There's no audio for song ID:", songId)
            }
        }
    }, [songRepository, data?.audio, songId])

    const cache = async () => {
        try {
            if (data?.audio) {
                setCaching(true)
                await songRepository.cacheAudioFile(data.audio.fileId)
                setData(song => {
                    if (song) {
                        return { ...song, audio: song.audio ? { ...song.audio, cached: true } : null }
                    } else {
                        return undefined
                    }
                })
                setCaching(false)
                loadAudio()
            }
        } catch (error) {
            console.log(error)
            setCaching(false)
        }
    }

    const seek = useCallback((time: number) => {
        const audio: HTMLAudioElement | null = audioRef.current
        if (audio) {
            audio.currentTime = time
        } else {
            console.log("Seeking without audio?")
        }
    }, [])

    const seekAndPlay = useCallback((time: number, useBuffer: boolean) => {
        console.log(time)
        const audio: HTMLAudioElement | null = audioRef.current
        if (audio) {
            seek(Math.max(time - (useBuffer ? playbackBuffer : 0), 0))
            audio.play()
            requestWakeLock()
        }
    }, [playbackBuffer, seek])

    const togglePlay = useCallback(() => {
        const audio: HTMLAudioElement | null = audioRef.current
        if (audio) {
            if (audio.paused) {
                audio.play()
                requestWakeLock()
            } else {
                releaseWakeLock()
                audio.pause()
            }
        }
    }, [])

    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.code === "Space") {
            togglePlay()
            event.preventDefault()
        }
        if (event.code === "Enter") {
            seekAndPlay((data?.audio?.startTimeMs || 0) / 1000.0, false)
            event.preventDefault()
        }

    }, [togglePlay, seekAndPlay, data?.audio]);

    const changeLanguage = (language: Language) => {
        navigate(`../${language}`, { relative: "path" })
    }

    const close = () => {
        const search = location.state?.search || ""

        navigate(`/songs${search}`)
    }

    useEffect(() => {
        const fetchSong = async () => {
            if (songId && language) {
                const song = await songRepository.getSong(songId)
                if (song) {
                    setData(song)
                } else {
                    navigate("/")
                }
            } else {
                console.error("No song ID")
            }
        }

        fetchSong()

    }, [songId, language, navigate, songRepository])

    useEffect(() => {
        if (data && data.audio?.cached) {
            loadAudio()
        }
    }, [data, loadAudio])

    useEffect(() => {
        if (data && !lyrics) {
            changeLanguage(data.lyrics.keySeq().first())
        }
    })

    useEffect(() => {
        if (audioBlobUrl && data?.audio && data.audio.startTimeMs) {
            seek(data.audio.startTimeMs / 1000.0)
        }
    }, [audioBlobUrl, data?.audio, seek])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        return () => {
            releaseWakeLock()
        };
    }, [])

    const requestWakeLock = async () => {
        if ('wakeLock' in navigator) {
          try {
            wakeLockRef.current = await navigator.wakeLock.request('screen')
          } catch (err: any) {
            console.error(`Failed to acquire wake lock: ${err.name}, ${err.message}`)
          }
        } else {
          console.error('Wake Lock API is not supported in this browser.')
        }
      }
    
      const releaseWakeLock = async () => {
        if (wakeLockRef.current) {
          try {
            await wakeLockRef.current.release()
            wakeLockRef.current = null
          } catch (err: any) {
            console.error(`Failed to release wake lock: ${err.name}, ${err.message}`)
          }
        }
      }


    return loading ? <div className="text-center py-3"><Spinner animation="border" /></div> :
        <>
            {lyrics &&
                <div className="main-container h-100 d-flex flex-column px-2 pt-2">
                    <div className="top mb-3 d-flex justify-content-end">
                        <Stack direction="horizontal" gap={1}>
                            {audioBlobUrl && <>
                                {playing ? <Button onClick={togglePlay}><FontAwesomeIcon icon={faPause} /></Button> : <Button onClick={togglePlay}><FontAwesomeIcon icon={faPlay} /></Button>}
                                <AudioPlayer src={audioBlobUrl} onListen={setTime} audioRef={audioRef} onPlay={() => setPlaying(true)} onPause={() => setPlaying(false)} />
                            </>}

                            {(audioLoading) ? <Button disabled><FontAwesomeIcon icon={faSpinner} spin /> Loading audio...</Button> :
                                (audioBlobUrl !== undefined) ? <Button disabled><FontAwesomeIcon icon={faCheck} /> Audio loaded</Button> :
                                    (data.audio === null) ? <Button disabled><FontAwesomeIcon icon={faDownload} /> No audio</Button> : <Button onClick={loadAudio}><FontAwesomeIcon icon={faCloudDownloadAlt} /> Load audio</Button>}
                            <Button disabled={!data.audio || data.audio.cached} variant="secondary" onClick={cache}>
                                {audioCaching ?
                                    <><FontAwesomeIcon icon={faSpinner} spin /> Storing locally...</> :
                                    <><FontAwesomeIcon icon={faHdd} /> Store locally</>
                                }
                            </Button>
                            <Button variant="secondary" onClick={() => navigate("edit")}><FontAwesomeIcon icon={faEdit} /> Edit</Button>
                            {language &&
                                (

                                    <LanguageSelector availableLanguages={availableLanguages || []} selectedLanguage={language} disabledLanguages={[language]} onChange={changeLanguage} variant="dropdown" disabled={availableLanguages && availableLanguages.length === 1} loading={availableLanguages === undefined} />
                                )
                            }
                            <Button variant="secondary" onClick={close}><FontAwesomeIcon icon={faClose} /> Close</Button>
                        </Stack>


                    </div>


                    <div className="lyrics-container flex-grow-1 d-flex flex-column overflow-hidden px-3">
                        {lyrics.characters.length > 1 &&
                            <div className="d-flex">
                                {lyrics.characters.map((character, characterIndex) => (
                                    <h3 className="flex-grow-1" key={characterIndex}>{character.name}</h3>
                                ))}
                            </div>
                        }
                        <div className="d-flex flex-grow-1 overflow-scroll">
                            {lyrics.characters.map((character, characterIndex) => (

                                <div key={characterIndex} className="flex-grow-1">
                                    <LyricsComponent
                                        playing={playing}
                                        lyrics={lyrics}
                                        characterIndex={characterIndex}
                                        time={time}
                                        onSeek={time => seekAndPlay(time, true)}
                                        leadTimeMs={leadTimeMs}
                                        audioLoaded={audioLoaded}
                                        coloured={lyrics.characters.length > 1}
                                    /></div>


                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
}

export default SingAlong