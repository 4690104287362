import { faCancel, faCheck, faDownload, faEdit, faMinusCircle, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import formatDuration from "format-duration"
import Immutable from "immutable"
import React, { useContext, useEffect, useState } from "react"
import { Badge, Button, Form, Spinner, Stack, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { AudioPlayer } from "../AudioPlayer"
import "../CharacterColours.css"
import { SongRepositoryContext } from "../SongRepositoryContext"
import CharacterForm from "../editor/CharactersForm"
import TitleForm from "../editor/TitleForm"
import { AudioFileId } from "../model/AudioFileId"
import { Character } from "../model/Character"
import { Language } from "../model/Language"
import { Line, Lyrics, LyricsBlock } from "../model/Lyrics"
import { Song } from "../model/Song"
import "./Editor.css"
import { LanguageSelector } from "./Parser"
import { MultiAsyncTypeahead } from "./TagForm"

const Editor: React.FC = () => {
    const songRepository = useContext(SongRepositoryContext)

    const params = useParams()
    const songId = params.songId
    const language: Language | undefined = params.language ? Language.parse(params.language) : undefined
    const navigate = useNavigate()

    const [loading, setLoading] = useState(!!songId)
    const [song, setSong] = useState<Song | undefined>(undefined)

    const [charactersValid, setCharactersValid] = useState(false)
    const lyrics = (song && language) ? song.lyrics.get(language) : undefined
    const characters = lyrics ? lyrics.characters : undefined
    const title = lyrics?.title || ""

    const [nonRemovableCharacters, setNonRemovableCharacters] = useState<number[]>([])
    const [textEdition, setTextEdition] = useState<boolean>(false)
    const [time, setTime] = useState<number>(0)

    const areLyricsValid = () => {
        return song?.lyrics.every(lyrics => {
            return lyrics.lineBlocks.every(block => {
                return block.characters.length > 0
            })
        })
    }

    const saveable = title.trim().length > 0 && charactersValid && areLyricsValid()

    const modifyBlocks = (f: (blocks: LyricsBlock[]) => LyricsBlock[]) => {
        modifyLyrics(lyrics => {
            return {
                ...lyrics, lineBlocks: f(lyrics.lineBlocks)
            }
        })
    }

    const modifyBlock = (blockIndex: number, f: (block: LyricsBlock) => LyricsBlock) => {
        modifyBlocks(blocks => blocks.map((block, index) => (index === blockIndex) ? f(block) : block))
    }

    const addBlockAfter = (blockIndex: number) => {
        modifyBlocks(blocks =>
            [...blocks.slice(0, blockIndex + 1), {
                lines: [{ text: "", characters: [] }],
                characters: []
            }, ...blocks.slice(blockIndex + 1)]
        )
    }

    const removeBlock = (block: LyricsBlock) => {
        modifyBlocks(blocks => blocks.filter(b => b !== block))
    }

    const addLineToBlock = (blockIndex: number) => {
        modifyBlock(blockIndex, block => ({ ...block, lines: [...block.lines, { text: "" }] }))
    }

    const removeLine = (blockIndex: number, line: Line) => {
        modifyBlock(blockIndex, block => {
            return { ...block, lines: block.lines.filter(l => l !== line) }
        })
    }

    const modifyLine = (blockIndex: number, lineIndex: number, f: (line: Line) => Line) => {
        modifyBlock(blockIndex, block => ({ ...block, lines: block.lines.map((line, index) => (index === lineIndex) ? f(line) : line) }))
    }

    const modifyLyrics = (f: (lyrics: Lyrics) => Lyrics) => {
        setSong(song => {
            if (song && language) {
                const lyrics: Lyrics | undefined = song?.lyrics.get(language)
                if (lyrics) {
                    return { ...song, lyrics: song.lyrics.set(language, f(lyrics)) }
                } else {
                    return song
                }
            } else {
                return song
            }
        })
    }

    const modifyCharacters = (f: (characters: Character[]) => Character[]) => {
        modifyLyrics(lyrics => {
            return { ...lyrics, characters: f(lyrics.characters) }
        })
    }

    const setTitle = (title: Song.Title) => {
        modifyLyrics(lyrics => ({ ...lyrics, title }))
    }

    const setAudioFileId = (fileId: AudioFileId) => {
        setSong(song => {
            if (song) {
                return { ...song, audio: { cached: false, fileId, startTimeMs: null } }
            } else {
                return song
            }
        })
    }

    const deleteAudioFileId = () => {
        setSong(song => {
            if (song) {
                return { ...song, audio: null }
            } else {
                return song
            }
        })
    }

    const saveTime = (blockIndex: number, lineIndex: number) => {
        if (time > 0) {
            modifyLine(blockIndex, lineIndex, line => ({ ...line, time }))
        }
    }

    const save = async () => {
        if (songId && song && lyrics) {
            setLoading(true)
            await songRepository.putSong(songId, song) // TODO characters
            goBack()
        }
    }

    const goBack = () => {
        navigate("..", { relative: "path" })
    }

    const changeLanguage = (language: Language) => {
        navigate(`../../${language}/edit`, { relative: "path" })
    }

    const deleteLyrics = () => {
        if (song) {
            changeLanguage(song.lyrics.keySeq().filter(l => l !== language).first())
            setSong(song => {
                if (song) {
                    return { ...song, lyrics: song.lyrics.filter((value, key) => key !== language) }
                } else {
                    return undefined
                }
            })
        }
    }

    const deleteSong = async () => {
        if (songId && window.confirm("Are you sure? This will delete all languages and audio file!")) {
            setLoading(true)
            await songRepository.deleteSong(songId)
            setLoading(false)
            navigate("/songs")
        }
    }

    const searchTags = async (query: string) => {
        return await songRepository.searchTags(query)
    }

    const setStartTime = (startTimeMs: number) => {
        setSong(song => {
            if (song && song.audio) {
                return { ...song, audio: { ...song.audio, startTimeMs } }
            } else {
                return song
            }
        })
    }



    useEffect(() => {
        if (song && !lyrics) {
            navigate("../parse", { relative: "path" })
        }
    }, [song, lyrics, navigate])

    useEffect(() => {
        const fetchData = async () => {
            if (songId) {
                const song = await songRepository.getSong(songId)
                if (song) {
                    setSong(song)
                    setLoading(false)
                } else {
                    navigate("../parse", { relative: "path" })
                }
            }
        }

        fetchData()
    }, [songId, songRepository, navigate])

    useEffect(() => {
        if (lyrics) {
            const characterSet: Immutable.Set<number> = lyrics.lineBlocks.reduce((set, block) => {
                return set.union([...block.characters])
            }, Immutable.Set.of<number>())
            setNonRemovableCharacters(characterSet.toArray())
        } else {
            setNonRemovableCharacters([])
        }
    }, [lyrics])


    return loading ? <div className="text-center py-3"><Spinner animation="border" /></div> : <div className="container py-3 editor h-100">
        <div className="d-flex h-100">
            {(lyrics && characters) && <><div className="lyrics d-flex flex-column h-100 me-3">
                <div className="d-flex justify-content-between pb-3">
                    <div></div>
                    <ToggleButtonGroup type="radio" name="lyrics-mode" value={textEdition ? "text-edition" : "recording"} onChange={value => setTextEdition(value === "text-edition")}>
                        <ToggleButton variant="secondary" id="lyrics-mode-recording" value="recording">Time recording</ToggleButton>
                        <ToggleButton variant="secondary" id="lyrics-mode-edition" value="text-edition">Text edition</ToggleButton>
                    </ToggleButtonGroup>
                    <div className="d-inline-block">
                        <LanguageSelector variant="dropdown" availableLanguages={Language.all()} disabledLanguages={language ? [language] : []} onChange={changeLanguage} />
                        <Button className="ms-1" variant="danger" disabled={song?.lyrics.size === 1} onClick={deleteLyrics}><FontAwesomeIcon icon={faTrashAlt} /></Button>
                    </div>
                </div>
                <div className="scrollable">
                    {lyrics.lineBlocks.map((block, blockIndex) => {
                        return <div className="block m-3" key={`block-${blockIndex}`}>
                            {textEdition ?
                                <CharacterSelector
                                    characters={characters}
                                    selected={block.characters}
                                    onUpdate={(characters, interleaving) => modifyBlock(blockIndex, block => ({ ...block, characters, interleaving }))}
                                    interleaving={block.interleaving || false}
                                    id={`block-${blockIndex}`}
                                />
                                : <>{block.characters.map(characterIndex => (
                                    <span key={characterIndex} className={classNames({ [characters[characterIndex].colour]: true, "coloured-character": true })}>{characters[characterIndex].name}</span>
                                ))}</>
                            }
                            {block.lines.map((line, lineIndex) => {
                                return <div className="line my-1" key={`block-${blockIndex}-line-${lineIndex}`}>
                                    {textEdition ?
                                        <div className="d-flex">
                                            <Form.Control type="text" value={line.text} onChange={e => modifyLine(blockIndex, lineIndex, line => ({ ...line, text: e.target.value }))} />
                                            <Button variant="link" className="text-danger" disabled={block.lines.length === 1} onClick={() => removeLine(blockIndex, line)}><FontAwesomeIcon icon={faMinusCircle} /></Button>
                                        </div> :
                                        <div>
                                            <span className="clickable" onClick={() => saveTime(blockIndex, lineIndex)}>
                                                {line.text}
                                            </span>
                                            {line.time && <Badge bg="secondary" className="ms-1">{formatDuration(line.time * 1000, { ms: true })}</Badge>}
                                        </div>
                                    }
                                </div>
                            })}
                            {textEdition && <div className="text-center">
                                <Button variant="success" size="sm" onClick={() => addBlockAfter(blockIndex)}>Add block</Button>
                                {" "}
                                <Button variant="danger" size="sm" onClick={() => removeBlock(block)}>Remove block</Button>
                                {" "}
                                <Button variant="success" size="sm" onClick={() => addLineToBlock(blockIndex)}>Add&nbsp;line</Button>
                            </div>}
                        </div>
                    })}
                </div>
            </div>

                <Form className="metadata">
                    <TitleForm title={title} onChange={setTitle} />
                    <hr />
                    <CharacterForm
                        characters={characters}
                        onChange={(f) => {
                            modifyCharacters((characters) => f(characters))
                        }}
                        onValidChange={setCharactersValid}
                        nonRemovable={nonRemovableCharacters} />
                    <hr />
                    {
                        songId && <div className="mt-2">
                            <AudioFileManager fileId={song?.audio?.fileId} onTimeChange={setTime} onUpload={setAudioFileId} onDelete={deleteAudioFileId} startTime={song?.audio?.startTimeMs || 0} onStartTimeMark={setStartTime} />
                        </div>
                    }
                    <hr />
                    <div>
                        <MultiAsyncTypeahead selected={song?.tags || []} onChange={tags => setSong(song => song ? ({ ...song, tags }) : undefined)} onSearch={searchTags} />
                    </div>
                    <hr />
                    <div className="text-center">
                        <Button variant="secondary" onClick={() => navigate("../parse", { relative: "path" })}><FontAwesomeIcon icon={faEdit} /> Edit raw</Button>
                        {" "}
                        <Button onClick={deleteSong} variant="danger" disabled={!!song?.audio}><FontAwesomeIcon icon={faTrash} /> Delete</Button>
                        {" "}
                        <Button onClick={goBack} variant="secondary" className="ms-3"><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
                        {" "}
                        <Button onClick={save} disabled={!saveable}><FontAwesomeIcon icon={faCheck} /> Save</Button>
                    </div>
                </Form></>}
        </div>
    </div>
}

interface CharacterSelectorProps {
    characters: Character[]
    selected: number[]
    interleaving: boolean
    onUpdate: (selected: number[], interleaving: boolean) => void
    id: string
}

const CharacterSelector: React.FC<CharacterSelectorProps> = ({ characters, selected, interleaving, onUpdate, id }) => {
    const toggleCharacter = (characterIndex: number) => {
        if (selected.includes(characterIndex)) {
            onUpdate(selected.filter(index => index !== characterIndex), interleaving)
        } else {
            onUpdate([...selected, characterIndex].sort(), interleaving)
        }
    }

    const setInterleaving = (interleaving: boolean) => {
        onUpdate(selected, interleaving)
    }

    return <div className="character-selector">
        <Stack direction="horizontal" gap={1}>
            {characters.map((character, characterIndex) => {
                return (
                    <Badge className={classNames({ active: selected.includes(characterIndex), character: true, [character.colour]: true, "coloured-character-background": true })} key={characterIndex}>
                        <span onClick={() => toggleCharacter(characterIndex)}>
                            {character.name}
                        </span>
                    </Badge>
                )
            })}
            <Form.Check type="switch" label="Interleaving" id={`${id}-interleaving-switch`} checked={interleaving} onChange={(e) => setInterleaving(e.target.checked)} className="ms-2" />
        </Stack>


    </div>
}


interface AudioFileManagerProps {
    fileId: AudioFileId | undefined
    onTimeChange?: (time: number) => void
    onUpload: (fileId: AudioFileId) => void
    onDelete: () => void
    startTime: number,
    onStartTimeMark?: (startTimeMs: number) => void
}

const AudioFileManager: React.FC<AudioFileManagerProps> = ({ fileId, onTimeChange, onUpload, onDelete, startTime, onStartTimeMark }) => {
    const songRepository = useContext(SongRepositoryContext)

    const [loading, setLoading] = useState(false)

    const [audioBlob, setAudioBlob] = useState<Blob | undefined>(undefined)
    const [audioBlobUrl, setAudioBlobUrl] = useState<string | undefined>(undefined)

    const [youTubeLink, setYouTubeLink] = useState("")

    const [time, setTime] = useState(0)

    useEffect(() => {
        if (audioBlob) {
            setAudioBlobUrl(URL.createObjectURL(audioBlob))
        } else {
            setAudioBlobUrl(undefined)
        }
    }, [audioBlob])

    const onFileSelected = async (file: File) => {
        if (file.type.startsWith("audio/")) {
            setLoading(true)
            const fileId = await songRepository.storeAudioFile(file)
            onUpload(fileId)
            setLoading(false)
        } else {
            console.error("Wrong file type", file.type)
        }
    }

    const downloadFromYouTube = async () => {
        setLoading(true)
        try {
            const fileId = await songRepository.downloadYouTubeAudio(youTubeLink)
            onUpload(fileId)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const deleteFile = async () => {
        if (fileId && window.confirm("Are you sure?")) {
            setLoading(true)
            await songRepository.deleteAudioFile(fileId)
            setAudioBlob(undefined)
            onDelete()
            setLoading(false)
        }
    }

    const fetch = async () => {
        if (fileId) {
            setLoading(true)

            const blob = await songRepository.getAudioFile(fileId)

            if (blob) {
                setAudioBlob(blob)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }
    }

    const markStart = () => {
        if (onStartTimeMark) {
            onStartTimeMark(Math.round(time * 1000))
        }
    }

    useEffect(() => {
        onTimeChange && onTimeChange(time)
    }, [time, onTimeChange])

    return <Form.Group controlId="instrumental-file" className="mb-3">
        <Form.Label>Instrumental</Form.Label>
        <div>
            {
                (loading ? <div className="text-center"><Spinner animation="border" /></div> :
                    (fileId ?
                        (
                            audioBlobUrl ? <div className="instrumental">
                                <Stack gap={2} direction="vertical">
                                    <Stack direction="horizontal">
                                        <AudioPlayer src={audioBlobUrl} controls onListen={time => setTime(time)} listenEventInterval={100} className="flex-grow-1" />
                                    </Stack>
                                    <span className="small ms-1 text-center">Starts at: {formatDuration(startTime, { leading: true, ms: true })}</span>
                                    <Stack gap={2} className="text-center align-items-center mx-auto" direction="horizontal">
                                        <Button size="sm" variant="secondary" className="text-nowrap" onClick={markStart}>Mark start</Button>

                                        <Button size="sm" variant="danger" onClick={deleteFile} className="text-nowrap">Delete audio file</Button>
                                    </Stack>

                                </Stack>

                            </div> : <div><Button onClick={fetch}><FontAwesomeIcon icon={faDownload} /> Load</Button></div>
                        )
                        : <Stack gap={2}>
                            <Form.Control type="file" onChange={(e) => onFileSelected((e.target as any).files[0])} accept="audio/*" />
                            <div className="text-center">or paste YouTube link:</div>
                            <div>
                                <Stack direction="horizontal" gap={2}>
                                    <Form.Control type="text" onChange={(e) => setYouTubeLink(e.target.value)} value={youTubeLink} placeholder="https://www.youtube.com/watch?v=video-ID" />
                                    <Button variant="success" onClick={downloadFromYouTube} disabled={youTubeLink.length < 10}>Download</Button>
                                </Stack>

                            </div>
                        </Stack>
                    )
                )
            }
        </div>

    </Form.Group>
}

export default Editor