import { faCancel, faCheck, faEarthAmericas, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext, useEffect, useState } from "react"
import { Button, Dropdown, Form, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { SongRepositoryContext } from "../SongRepositoryContext"
import { Character } from "../model/Character"
import { Language } from "../model/Language"
import { Lyrics, LyricsBlock } from "../model/Lyrics"
import CharacterForm from "./CharactersForm"
import TitleForm from "./TitleForm"
import Immutable from "immutable"
import { MultiAsyncTypeahead } from "./TagForm"

interface Props {

}

const Parser: React.FC<Props> = () => {
    const navigate = useNavigate()
    const songRepository = useContext(SongRepositoryContext)

    const params = useParams()
    const songId = params.songId
    const language: Language | undefined = params.language ? Language.parse(params.language) : undefined


    const [loading, setLoading] = useState(!!songId)
    const [title, setTitle] = useState("")
    const [characters, setCharacters] = useState<Character[]>([])
    const [charactersValid, setCharactersValid] = useState(false)
    const [rawLyrics, setRawLyrics] = useState("")
    const [tags, setTags] = useState<string[]>([])

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(language || Language.English)

    const saveable = title.trim().length > 0 && charactersValid && rawLyrics.trim().length > 0

    const goBack = () => {
        navigate("..", { relative: "path" })
    }

    const parseAndSave = async () => {
        const effectiveLanguage = language || selectedLanguage

        if (effectiveLanguage) {
            setLoading(true)
            const blocks: LyricsBlock[] = rawLyrics.split(/\n{2,}/).flatMap(rawBlock => {
                const maybeBlock = parseBlock(rawBlock, characters)
                return (maybeBlock) ?
                    [maybeBlock]
                    :
                    []
            })

            const blockWithCharacters = fillInMissingCharacters(blocks)

            const lyrics: Lyrics = {
                lineBlocks: blockWithCharacters,
                title,
                language: effectiveLanguage,
                characters
            }

            if (songId) {
                setLoading(false)
                songRepository.putLyrics(songId, lyrics)
                goBack()
            } else {
                const createdSongId = await songRepository.addSong(
                    {
                        lyrics: Immutable.Map<Language, Lyrics>({ [lyrics.language]: lyrics }),
                        leadTimeMs: 0,
                        audio: null,
                        tags
                    }
                )
                navigate(`/songs/${createdSongId}/lyrics/${effectiveLanguage}`)
            }
            setLoading(false)
        }
    }


    const parseBlock = (raw: string, characters: Character[]) => {
        const lines = raw.split(/\n/).filter(line => line.trim() !== "").map(text => ({ text }))

        if (lines[0]) {
            const strippedFirstLine = lines[0].text.replace(/^\[/, "").replace(/\]$/, "")
            const characterIndex = characters.findIndex(character => character.name === strippedFirstLine)
            if (characterIndex !== -1) {
                return {
                    characters: [characterIndex],
                    lines: lines.slice(1)
                } as LyricsBlock
            } else {
                return {
                    characters: [0],
                    lines,
                    interleaving: false
                } as LyricsBlock
            }
        }
    }

    const fillInMissingCharacters = (blocks: LyricsBlock[]) => {
        return blocks.reduce((blocks, block) => {
            if (block.characters.length === 0 && blocks.length > 0 && blocks[blocks.length - 1].characters.length > 0) {
                return [...blocks, { ...block, characters: blocks[blocks.length - 1].characters }]
            } else {
                return [...blocks, block]
            }
        }, [] as LyricsBlock[])
    }


    useEffect(() => {
        const fetch = async () => {
            if (songId && language) {
                setLoading(true)
                const song = await songRepository.getSong(songId)
                const lyrics = song?.lyrics.get(language)
                if (lyrics) {
                    setTitle(lyrics.title)
                    setCharacters(lyrics.characters)
                    setRawLyrics(lyrics.lineBlocks.reduce((accBlocks, block) => {
                        const rawLines = block.lines.reduce((accLines, line) => {
                            return accLines + line.text + "\n"
                        }, "")

                        const characters = block.characters.length > 0 ? "[" + block.characters.map(characterIndex => {
                            return lyrics.characters[characterIndex].name
                        }).join(", ") + "]\n" : ""

                        return accBlocks + characters + rawLines + "\n\n"
                    }, "").trim())
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }
        }

        fetch()
    }, [songId, language, songRepository])

    return <div className="container py-3 h-100">
        {
            loading ? <div className="text-center"><Spinner animation="border" /></div> :
                <div className="d-flex flex-column h-100">
                    <div className="row flex-grow-1">
                        <div className="ms-2 col-md d-flex flex-column">
                            <Form.Label>
                                Raw lyrics
                            </Form.Label>
                            <Form.Control as="textarea" className="w-100 flex-grow-1" placeholder="Paste raw lyrics here" onChange={(e) => setRawLyrics(e.target.value)} value={rawLyrics} />
                        </div>
                        <div className="ms-3 col-md">
                            <TitleForm title={title} onChange={setTitle} />
                            <CharacterForm
                                characters={characters}
                                onChange={(f) => {
                                    setCharacters((characters) => f(characters))
                                }}
                                onValidChange={setCharactersValid}
                                nonRemovable={[]}
                            />
                            <div className="mt-3">
                                <Form.Label>Language</Form.Label>
                                <div>
                                    <LanguageSelector variant="radio" selectedLanguage={selectedLanguage} onChange={setSelectedLanguage} disabled={!!songId} availableLanguages={Language.all()} />
                                </div>
                            </div>
                            <div className="mt-3">
                                <MultiAsyncTypeahead selected={tags} onChange={tags => setTags(tags)} onSearch={songRepository.searchTags} />
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-3">
                        <Button variant="secondary" onClick={goBack}><FontAwesomeIcon icon={faCancel} /> Cancel</Button>
                        {" "}
                        <Button variant="success" onClick={parseAndSave} disabled={!saveable} ><FontAwesomeIcon icon={faCheck} /> Parse and save</Button>
                    </div>
                </div>
        }

    </div>
}

interface LanguageSelectorProps {
    variant: "dropdown" | "radio"
    selectedLanguage?: Language,
    onChange: (language: Language) => void
    availableLanguages: Language[]
    disabledLanguages?: Language[]
    disabled?: boolean
    loading?: boolean
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ variant, selectedLanguage, onChange, availableLanguages, disabled, disabledLanguages, loading }) => {
    return (variant === "dropdown" ?
        <Dropdown className="d-inline-block">
            <Dropdown.Toggle variant="secondary" id="language-selection" disabled={disabled || loading}>{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faEarthAmericas} />} Language</Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    availableLanguages.sort().map(language => {
                        return <Dropdown.Item key={language} onClick={() => onChange(language)} disabled={(disabledLanguages && disabledLanguages.includes(language))}>{language.toUpperCase()}</Dropdown.Item>
                    })
                }
            </Dropdown.Menu>
        </Dropdown> :
        <ToggleButtonGroup type="radio" name={`language-selection`} value={selectedLanguage} onChange={onChange}>

            {availableLanguages.map(language => {
                return <ToggleButton id={`language-selection-${language}`} value={language} key={language} disabled={disabledLanguages && disabledLanguages.includes(language)}>{language.toUpperCase()}</ToggleButton>
            })}

        </ToggleButtonGroup>)

}

export default Parser