import React, { RefObject, useEffect, useRef, useState } from "react";

interface Props {
    src: string
    onListen?: (time: number) => void
    audioRef?: RefObject<HTMLAudioElement>
    listenEventInterval?: number
    controls?: boolean
    onPlay?: () => void
    onPause?: () => void
    className?: string
}

export const AudioPlayer: React.FC<Props> = ({ src, audioRef, listenEventInterval, onListen, controls, onPlay, onPause, className }) => {
    const internalRef = useRef<HTMLAudioElement>(null)

    const ref = audioRef || internalRef

    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        const playHandler = (e: Event) => {
            setPlaying(true)
        }

        const pauseHandler = (e: Event) => {
            setPlaying(false)
        }

        if (ref.current) {
            setPlaying(!ref.current.paused)
            ref.current.addEventListener("play", playHandler)
            ref.current.addEventListener("pause", pauseHandler)
            ref.current.addEventListener("ended", pauseHandler)
        }
    }, [ref])

    useEffect(() => {
        if (playing && onPlay) {
            onPlay()
        } else if (!playing && onPause) {
            onPause()
        }
    }, [playing, onPause, onPlay])

    useEffect(() => {
        if (ref.current && playing && onListen) {
            const interval = window.setInterval(() => {
                if (ref.current && !ref.current.paused) {
                    onListen(ref.current.currentTime)
                }
            }, listenEventInterval || 250)

            return () => {
                window.clearInterval(interval)
            }
        }
    }, [ref, onListen, listenEventInterval, playing])

    return <audio src={src} controls={!!controls} ref={ref} className={className} />

}