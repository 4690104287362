import React from "react"
import { Form } from "react-bootstrap"

interface Props {
    title: string
    onChange: (title: string) => void
}

const TitleForm: React.FC<Props> = ({ title, onChange }) => {
    return <div>
        <Form.Label>Title</Form.Label>
        <Form.Control type="text" placeholder="Title" value={title} onChange={e => onChange(e.target.value)} />
    </div >
}

export default TitleForm