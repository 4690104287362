import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useEffect } from 'react';
import { RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom';
import './App.css';
import SingAlong from './SingAlong';
import { SongList } from './SongList';
import { HttpSongRepository, IndexedDbSongRepository } from './SongRepository';
import { SongRepositoryContext } from './SongRepositoryContext';
import Editor from './editor/Editor';
import Parser from './editor/Parser';
import { AxiosContext } from './AxiosContext';

function App() {

  const RedirectToSongs = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/songs');
    }, [navigate]);
    return <></>;
  }

  const axios = useContext(AxiosContext)

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RedirectToSongs />
    },
    {
      path: "/songs",
      element: <SongList />,
    },
    {
      path: "/songs/:songId/lyrics/:language",
      element: <SingAlong />
    },
    {
      path: "/songs/:songId/lyrics/:language/parse",
      element: <Parser />
    },
    {
      path: "/songs/:songId/lyrics/:language/edit",
      element: <Editor />
    },
    {
      path: "/songs/add",
      element: <Parser />
    },
    {
      path: "/signed-in",
      element: <RedirectToSongs />
    },
    {
      path: "/signed-out",
      element: <RedirectToSongs />
    }
  ])

  return (
    <div className="App">
      
        <SongRepositoryContext.Provider value={new HttpSongRepository(axios, new IndexedDbSongRepository())}>
          <RouterProvider router={router} />
        </SongRepositoryContext.Provider>
        
    </div>
  );
}



export default App;
