import axios, { Axios, AxiosError, AxiosRequestConfig } from "axios";
import { UserManager } from "oidc-react";
import { createContext, useContext } from "react";

export const createAxiosContext = (userManager: UserManager, refreshToken: () => void) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const MAX_RETRIES = 3

    const configuredAxios = axios.create({ baseURL: backendUrl })

    configuredAxios.interceptors.request.use(async config => {
        const userData = await userManager.getUser()
        const token = userData?.access_token

        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config
    })


    type ExtendedConfig = AxiosRequestConfig & { retryCount?: number }

    configuredAxios.interceptors.response.use((value) => value, async (error: AxiosError) => {
        const config: ExtendedConfig | undefined = error.config

        // Check if we've run out of retries
        if (!config || (error.response?.status !== 401) || (config.retryCount && config.retryCount >= MAX_RETRIES)) {
            return Promise.reject(error)
        }

        config.retryCount = config.retryCount || 0
        config.retryCount += 1

        // Retry the request
        await refreshToken()
        return configuredAxios(config)
    })

    return configuredAxios
}


export const AxiosContext = createContext<Axios>(axios)

export const useAxios = () => {
    const axiosInstance = useContext(AxiosContext)
    if (!axiosInstance) {
        throw new Error('useAxios must be used within an AxiosProvider')
    }
    return axiosInstance
}