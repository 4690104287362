import { useEffect } from "react"

export const useDocumentTitle = (title: string) => {
    const previousTitle = document.title

    useEffect(() => {
        document.title = title
        return () => {
            document.title = previousTitle
        }
    }, [title, previousTitle])
}
