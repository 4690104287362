import { WebStorageStateStore } from "oidc-client-ts"
import { AuthProvider, UserManager } from "oidc-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Spinner } from "react-bootstrap"
import { AxiosContext, createAxiosContext } from "./AxiosContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import PQueue from 'p-queue';

interface Props {
    children?: React.ReactNode
}

export function AsyncAuthProvider({ children }: Props) {
    const [loading, setLoading] = useState(true)
    const [signedIn, setSignedIn] = useState(false)

    const audience = process.env.REACT_APP_BACKEND_URL || ""

    const userManager = useMemo(() => new UserManager({
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        authority: process.env.REACT_APP_OIDC_PROVIDER_URL || "",
        client_id: process.env.REACT_APP_OIDC_CLIENT_ID || "",
        redirect_uri: window.location.origin + "/signed-in",
        post_logout_redirect_uri: window.location.origin + "/signed-out",
        scope: "offline_access",
        automaticSilentRenew: false
    }), [])

    const queue = useMemo(() => new PQueue({ concurrency: 1 }), [])

    const refreshToken = useCallback(async () => {
        const user = await userManager.getUser()
        console.log(user?.expires_in)
        if (!!user?.expires_in && user?.expires_in <= 60) {
            console.log("Refreshing token...")
            try {
                await userManager.signinSilent()
            } catch (e) {
                if ((e as any).name === "ErrorResponse") {
                    console.error("Got error response, signing out...", e)
                    await userManager.removeUser()
                    setSignedIn(false)
                }
                console.error(e)
            }
            console.log("Refreshed token")
        } else {
            console.log("Looks like token is already refreshed")
        }
    }, [userManager])

    const queueRefreshToken = useCallback(async () => {
        console.log("Queueing refresh token...")
        return queue.add(refreshToken)
    }, [queue, refreshToken])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true)
                const user = await userManager.getUser()
                setSignedIn(!!user)
            } catch (e) {
                
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        const onSignedIn = () => {
            setSignedIn(true)
        }

        const onAccessTokenExpiring = () => {
            console.debug("Access token expiring, refreshing...")
            queueRefreshToken()
        }

        const onAccessTokenExpired = () => {
            console.warn("Access token expired, refreshing...")
            queueRefreshToken()
        }

        userManager.events.addUserLoaded(onSignedIn)
        userManager.events.addAccessTokenExpired(onAccessTokenExpired);
        userManager.events.addAccessTokenExpiring(onAccessTokenExpiring)

        fetchUser()

        return () => {
            userManager.events.removeUserLoaded(onSignedIn)
            userManager.events.removeAccessTokenExpired(onAccessTokenExpired)
            userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring)
        }

    }, [userManager, queueRefreshToken])

    return (
        <AuthProvider userManager={userManager} autoSignIn={false} autoSignOut={false}>
            {(signedIn && !loading) ?
                <AxiosContext.Provider value={createAxiosContext(userManager, queueRefreshToken)}>
                    {children}
                </AxiosContext.Provider> :
                <div className="container text-center mt-3">
                    {loading ? <><Spinner animation="border" /></> :
                        <Button onClick={() => { setLoading(true); userManager.signinRedirect({ extraQueryParams: { audience }, extraTokenParams: { audience } }) }}><FontAwesomeIcon icon={faLock} /> Sign in</Button>
                    }
                </div>
            }
        </AuthProvider >
    )
}
