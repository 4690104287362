import Immutable from "immutable";
import { AudioMeta } from "./AudioMeta";
import { Language } from "./Language";
import { Lyrics } from "./Lyrics";

export interface Song {
    lyrics: Immutable.Map<Language, Lyrics>
    leadTimeMs: number
    audio: AudioMeta | null
    tags: string[]
}

export namespace Song {
    export type Id = string

    export type Title = string

    export type Item = {
        id: Song.Id,
        titles: Immutable.Map<Language, Song.Title>,
        tags: string[]
    }

    export const from = (object: any): Song => {
        return {
            leadTimeMs: object.leadTimeMs,
            audio: object.audio,
            tags: object.tags,
            lyrics: Immutable.Map(object.lyrics)
        }
    }
}