import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { Option } from "react-bootstrap-typeahead/types/types"
import "./Typeahead.css"

interface Props {
    selected: string[]
    onSearch: (query: string) => Promise<string[]>
    onChange: (tags: string[]) => void
}

export const MultiAsyncTypeahead: React.FC<Props> = ({ selected, onSearch, onChange }) => {
    const [loading, setLoading] = useState(false)

    const [options, setOptions] = useState<string[]>([])

    const availableOptions = options.filter(option => !selected.includes(option))

    const handleSearch = async (query: string) => {
        setLoading(true)

        try {
            const options = await onSearch(query)
            setOptions([...options])
            setLoading(false)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onChangeInternal = (selected: Option[]) => {
        const strings = selected.map((value) => {
            if (typeof value === "string") {
                return value as string
            } else {
                value as Record<string, any>
                return value.label
            }
        })
        onChange(strings)
    }

    return <>
        <Form.Label>Tags</Form.Label>
        <AsyncTypeahead
            multiple
            id="tags-typeahead"
            isLoading={loading}
            minLength={1}
            onSearch={handleSearch}
            options={availableOptions}
            placeholder="Search for tags..."
            allowNew
            selected={selected}
            onChange={onChangeInternal}
            renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>{results.map((result, index) => (
                    <MenuItem option={result} position={index}>
                        {typeof result === "string" ? result : result.label}
                    </MenuItem>
                ))}</Menu>
            )}
        />
        
    </>
}