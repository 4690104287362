import { Line, Lyrics, LyricsBlock } from "./model/Lyrics"
import { useCallback, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import formatDuration from "format-duration"
import "./LyricsComponent.css"
import "./CharacterColours.css"
import { useDocumentTitle } from "./useDocumentTitle"

interface LyricsComponentProps {
    lyrics: Lyrics,
    characterIndex: number,
    time: number
    onSeek: (time: number) => void,
    leadTimeMs: number
    audioLoaded?: boolean
    playing: boolean
    coloured: boolean
}

export const LyricsComponent = ({ lyrics, time, onSeek, characterIndex, leadTimeMs, audioLoaded, playing, coloured }: LyricsComponentProps) => {
    const [activeBlock, setActiveBlock] = useState<number | undefined>(undefined)
    const [activeLine, setActiveLine] = useState<number | undefined>(undefined)
    const [activeCharacter, setActiveCharacter] = useState<number | undefined>(undefined)
    const ref = useRef<HTMLDivElement>(null)

    useDocumentTitle(`${lyrics.title} — Songs`)

    const isOwn = useCallback((block: LyricsBlock) => {
        return block.characters.findIndex(index => index === characterIndex) !== -1
    }, [characterIndex])

    const onClick = (line: Line) => {
        if (line.time && audioLoaded) {
            setActiveBlock(undefined)
            setActiveLine(undefined)
            setActiveCharacter(undefined)
            onSeek(line.time)
        }
    }

    useEffect(() => {
        const timeWithLead = time + leadTimeMs / 1000
        const result = lyrics.lineBlocks.flatMap((block, blockIndex) => block.lines.map((line, lineIndex) => ({ line, blockIndex, lineIndex, notInterleavingOrOwn: !block.interleaving || isOwn(block), own: isOwn, characterIndex: block.characters[0] }))).reverse().find(lineMeta =>
            lineMeta.line.time && lineMeta.line.time < timeWithLead && lineMeta.notInterleavingOrOwn
        )
        if (result) {
            setActiveBlock(result.blockIndex)
            setActiveLine(result.lineIndex)
            setActiveCharacter(result.characterIndex)
        }
    }, [time, isOwn, leadTimeMs, lyrics.lineBlocks])

    useEffect(() => {
        if (activeBlock !== undefined && activeLine !== undefined && activeCharacter !== undefined) {
            if (activeLine % 8 === 0) {
                document.getElementById(`character-${activeCharacter}-block-${activeBlock}-line-${activeLine}`)?.scrollIntoView({behavior: "smooth", block: "start"})
            }
        }

    }, [activeBlock, activeLine, activeCharacter, lyrics.lineBlocks])

    return <div className={classNames({"lyrics": true, "keep-track": playing || time > 0, "audio-loaded": audioLoaded})} ref={ref}>
        {lyrics.lineBlocks.map((block, blockIndex) => {
            const own = isOwn(block)
            const characterDefined = block.characters.length > 0

            return <p className={classNames({ "interleaving": block.interleaving, "own": own, "other-character": !own && characterDefined, }, "align-items-start flex-column d-flex")} key={blockIndex}>
                {block.lines.map((line, lineIndex) => {

                    const colour = own ? lyrics.characters[characterIndex].colour : "gray"

                    return (
                        <span key={lineIndex} className={classNames({ "line": true, "active": blockIndex === activeBlock && lineIndex === activeLine, "has-time": !!line.time, [colour]: true, "coloured-character": coloured })} onClick={() => onClick(line)}>
                            <b id={`character-${characterIndex}-block-${blockIndex}-line-${lineIndex}`} style={{position: "relative", top: "-2em"}}></b>
                            <span className="text">{line.text}</span>
                            {line.time && <span className="time">{formatDuration(line.time * 1000, { leading: true, ms: true })}</span>}
                        </span>
                    )
                })
                }
            </p>
        })}
    </div>
}