import { DBSchema, IDBPDatabase, openDB } from "idb";
import { Song } from "./model/Song";
import cuid2 from "@paralleldrive/cuid2";
import { Lyrics } from "./model/Lyrics";
import { Language } from "./model/Language";
import { Axios, AxiosError } from "axios"
import { AudioFileId } from "./model/AudioFileId";
import Immutable from "immutable";

export interface SongRepository {
    list(term?: string): Promise<Song.Item[]>

    getSong(songId: Song.Id): Promise<Song | undefined>
    putSong(songId: Song.Id, song: Song): Promise<void>
    addSong(song: Song): Promise<Song.Id>
    putLyrics(songId: Song.Id, lyrics: Lyrics): Promise<void>
    deleteSong(songId: Song.Id): Promise<void>

    searchTags(term: string): Promise<string[]>

    cacheAudioFile(fileId: AudioFileId): Promise<void>
    getAudioFile(audioFileId: AudioFileId): Promise<Blob | undefined>
    downloadYouTubeAudio(link: string): Promise<AudioFileId>
    storeAudioFile(data: Blob): Promise<AudioFileId>
    deleteAudioFile(fileId: AudioFileId): Promise<void>
}

interface SongDB extends DBSchema {
    lyrics: {
        key: Song.Id
        value: Lyrics
    }
    songs: {
        key: Song.Id
        value: Song
    }
    audioFiles: {
        key: AudioFileId
        value: Blob
    }
}

export class IndexedDbSongRepository implements SongRepository {
    private readonly dbName = "SongDB";
    private dbPromise: Promise<IDBPDatabase<SongDB>>;

    constructor() {
        this.dbPromise = openDB<SongDB>(this.dbName, 2, {
            upgrade(db) {
                if (!db.objectStoreNames.contains("lyrics")) {
                    db.createObjectStore("lyrics")
                }
                if (!db.objectStoreNames.contains("songs")) {
                    db.createObjectStore("songs")
                }
                if (!db.objectStoreNames.contains("audioFiles")) {
                    db.createObjectStore("audioFiles")
                }
            }
        });
    }

    async deleteSong(songId: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async list(): Promise<Song.Item[]> {
        const db = await this.dbPromise
        const ids = await db.getAllKeys("songs")

        const lyrics = await Promise.all(ids.map(async songId => {
            const song = await db.get("songs", songId) as Song
            return Immutable.Map(song.lyrics).toList().map((lyrics) => {
                return { id: songId, titles: Immutable.Map({ [lyrics.language]: lyrics.title }), tags: [] } as Song.Item
            })
        }))

        return lyrics.flatMap(x => x.toArray());
    }

    async getSong(songId: Song.Id): Promise<Song | undefined> {
        const db = await this.dbPromise
        const song = await db.get("songs", songId)

        if (song) {
            return Song.from(song)
        } else {
            return undefined
        }

    }

    async putSong(songId: string, song: Song): Promise<void> {
        const db = await this.dbPromise
        await db.put("songs", song, songId)
    }

    async putLyrics(songId: Song.Id, lyrics: Lyrics): Promise<void> {
        const readSong: Song | undefined = await this.getSong(songId)

        if (readSong) {
            return this.putSong(songId, { ...readSong, lyrics: readSong.lyrics.set(lyrics.language, lyrics) })
        } else {
            const lyricsMap = Immutable.Map<Language, Lyrics>({ [lyrics.language]: lyrics })

            return this.putSong(songId, { lyrics: lyricsMap, leadTimeMs: 0, audio: null, tags: [] })
        }
    }

    async addSong(song: Song): Promise<Song.Id> {
        const songId = cuid2.createId()
        this.putSong(songId, song)
        return songId
    }

    async cacheAudioFile(fileId: AudioFileId): Promise<void> {
        return Promise.resolve()
    }

    async getAudioFile(fileId: AudioFileId): Promise<Blob | undefined> {
        const db = await this.dbPromise
        return db.get("audioFiles", fileId);
    }

    async storeAudioFile(data: Blob, fileId?: AudioFileId): Promise<AudioFileId> {
        const resultingFileId = fileId || cuid2.createId()
        const db = await this.dbPromise
        await db.put("audioFiles", data, resultingFileId);
        return resultingFileId
    }

    async deleteAudioFile(songId: string): Promise<void> {
        const db = await this.dbPromise
        await db.delete("audioFiles", songId)
    }

    async searchTags(term: string): Promise<string[]> {
        throw new Error("Method not implemented.");
    }

    downloadYouTubeAudio(link: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
}

export class HttpSongRepository implements SongRepository {

    private localSongRepository: IndexedDbSongRepository

    private axios: Axios

    constructor(axios: Axios, localSongRepository: IndexedDbSongRepository) {
        this.axios = axios
        this.localSongRepository = localSongRepository
    }

    async list(term?: string): Promise<Song.Item[]> {
        const response = await this.axios.get("/v2/songs", { params: { term } })

        return response.data.map((song: any) => {
            return { id: song.id, titles: Immutable.Map(song.titles), tags: song.tags }
        })
    }

    // TODO 404?
    async getSong(songId: Song.Id): Promise<Song | undefined> {
        try {
            const response = await this.axios.get(`/v2/songs/${songId}`)
            const song = Song.from(response.data)

            const cachedAudio = song?.audio ? !!await this.localSongRepository.getAudioFile(song.audio.fileId) : false
            
            return { ...song, audio: song.audio ? { ...song.audio, cached: cachedAudio } : null }
        } catch (e) {
            if (e instanceof AxiosError && e.status === 404) {
                return undefined
            } else {
                throw e
            }
        }
    }

    async putSong(songId: string, song: Song): Promise<void> {
        await this.axios.put(`/v2/songs/${songId}`, song)
    }
    async addSong(song: Song): Promise<Song.Id> {
        const response = await this.axios.post(`/v2/songs`, song)
        return response.data
    }
    async putLyrics(songId: string, lyrics: Lyrics): Promise<void> {
        await this.axios.put(`/v2/songs/${songId}/lyrics/${lyrics.language}`, lyrics)
    }

    async deleteSong(songId: Song.Id): Promise<void> {
        await this.axios.delete(`/v2/songs/${songId}`)
    }
    
    async searchTags(term: string): Promise<string[]> {
        const response = await this.axios.get(`/v2/songs/tags`, {params: {term}})
        return response.data
    }

    async cacheAudioFile(fileId: AudioFileId): Promise<void> {
        const blob = await this.getAudioFile(fileId)
        if (blob) {
            this.localSongRepository.storeAudioFile(blob, fileId)
        }
    }
    // TODO 404?
    async getAudioFile(audioFileId: string): Promise<Blob | undefined> {
        const localResponse = await this.localSongRepository.getAudioFile(audioFileId)
        if (localResponse) {
            return localResponse
        } else {
            const response = await this.axios.get(`/v2/audio/${audioFileId}`, { responseType: "blob" })
            return response.data
        }
    }
    async storeAudioFile(data: Blob): Promise<AudioFileId> {
        const response = await this.axios.post(`/v2/audio`, data)
        return response.data
    }
    async deleteAudioFile(fileId: string): Promise<void> {
        await this.axios.delete(`/v2/audio/${fileId}`)
    }

    async downloadYouTubeAudio(link: string): Promise<AudioFileId> {
        const response = await this.axios.post(`/v2/audio/youtube`, undefined, {params: {videoUrl: link}})
        return response.data
    }


}