import React, { useEffect } from "react"
import { Character } from "../model/Character"
import { Button, Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faRemove } from "@fortawesome/free-solid-svg-icons"

interface Props {
    characters: Character[]
    onChange: (f: (characters: Character[]) => Character[]) => void
    onValidChange: (valid: boolean) => void
    nonRemovable: number[]
}

const CharacterForm: React.FC<Props> = ({ characters, onChange, nonRemovable, onValidChange }) => {
    const colours = ["blue", "green", "red", "yellow"]

    const validate = (characters: Character[]) => {
        return characters.every(character => {
            return character.name.trim().length > 0
        }) && characters.length > 0
    }

    const onChangeValidate = (f: (characters: Character[]) => Character[]) => {
        onChange(characters => f(characters))
    }



    const addCharacter = () => {
        onChangeValidate(characters => [...characters, { name: "", colour: "blue" }])
    }

    const editCharacter = (characterToEditIndex: number, f: (character: Character) => Character) => {
        onChangeValidate(characters => characters.map((character, index) =>
            (index === characterToEditIndex) ?
                f(character) :
                character
        ))
    }

    const removeCharacter = (characterToRemoveIndex: number) => {
        onChangeValidate(characters => characters.filter((character, characterIndex) => characterIndex !== characterToRemoveIndex))
    }

    useEffect(() => {
        onValidChange(validate(characters))
    }, [characters, onValidChange])

    return <div className="mt-2">
        <Form.Label>Characters</Form.Label>
        <div>
            {characters.map((character, characterIndex) => {
                return <div className="mb-2 px-2 d-flex align-items-stretch" key={`character-${characterIndex}`}>
                    <div className="flex-grow-1 me-2">
                        <Form.Label>
                            Name
                        </Form.Label>
                        <Form.Control type="text" value={character.name} onChange={(e) => editCharacter(characterIndex, character => ({ ...character, name: e.target.value }))} />
                    </div>
                    <div className="ms-2">
                        <Form.Label>Colour</Form.Label>
                        <div>
                            <ToggleButtonGroup type="radio" name={`character-${characterIndex}-colour`} value={character.colour} onChange={value => editCharacter(characterIndex, character => ({ ...character, colour: value }))}>
                                {colours.map(colour => {
                                    return <ToggleButton id={`character-${characterIndex}-colour-${colour}`} value={colour} key={colour}>{colour}</ToggleButton>
                                })}

                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div className="ms-2">
                        <Form.Label style={{ visibility: "hidden" }}>Delete</Form.Label>
                        <div>
                            <Button variant="danger" onClick={() => removeCharacter(characterIndex)} disabled={nonRemovable.includes(characterIndex)}><FontAwesomeIcon icon={faRemove} /></Button>
                        </div>
                    </div>
                </div>
            })}
            <div className="text-center mt-3">
                {(characters.length < 4) && <Button variant="success" size="sm" onClick={addCharacter}><FontAwesomeIcon icon={faAdd} /> Add character</Button>}
            </div>

        </div>
    </div>
}

export default CharacterForm