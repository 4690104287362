import { useContext, useEffect, useRef, useState } from "react"
import { SongRepositoryContext } from "./SongRepositoryContext"
import { Song } from "./model/Song"
import { Button, Form, ListGroup, Spinner } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faSignOut } from "@fortawesome/free-solid-svg-icons"
import { LinkContainer } from "react-router-bootstrap"
import { useAuth } from "oidc-react"

export const SongList: React.FC = () => {
    const songRepository = useContext(SongRepositoryContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [songs, setSongs] = useState<Song.Item[]>([])
    const searchParams = new URLSearchParams(location.search)
    const [term, setTerm] = useState(searchParams.get("term") || "")
    const auth = useAuth()

    const termInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const getData = setTimeout(async () => {
            const songItems = await songRepository.list(term)
            navigate(`?term=${encodeURIComponent(term)}`)
            setSongs(songItems)
            setLoading(false)
        }, 200)

        return () => clearTimeout(getData)
    }, [term, songRepository, navigate])

    return (
        <div className="container">
            {loading ? <div className="text-center py-3"><Spinner animation="border" /></div> :
                <div className="py-3">
                    <Form.Control className="mb-3" placeholder="Search..." value={term} onChange={e => setTerm(e.target.value)} ref={termInputRef} />
                    <ListGroup>
                        {songs.flatMap(song => {
                            return song.titles.entrySeq().map(([language, title]) => {
                                return <LinkContainer to={`/songs/${song.id}/lyrics/${language}`} key={`${song.id}-${language}`} state={{search: location.search}}>
                                    <ListGroup.Item action>
                                        <h3>{title}</h3>
                                        <div className="text-muted">
                                            {
                                                song.tags.map(tag => {
                                                    return <span key={tag} className="me-1">#{tag}</span>
                                                })
                                            }

                                        </div>
                                    </ListGroup.Item>
                                </LinkContainer>
                            })
                        })}
                    </ListGroup>
                </div>
            }
            <div className="text-center">
                <Button variant="success" onClick={() => navigate("/songs/add")}><FontAwesomeIcon icon={faAdd} /> Add a song</Button>
            </div>
            <div className="mt-3 pb-3 text-center">
                <Button variant="secondary" onClick={() => auth.signOutRedirect()}><FontAwesomeIcon icon={faSignOut} /> Sign out</Button>
            </div>

        </div>

    )
}