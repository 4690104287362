export enum Language {
    English = "en",
    Polish = "pl",
    French = "fr",
    Spanish = "es",
    Czech = "cz"
}

export namespace Language {
    export const parse = (value: string): Language | undefined => {
        if (Object.values(Language).includes(value as Language)) {
            return value as Language;
        }
        return undefined;
    }

    export const all = (): Language[] => {
        return Object.values(Language).filter(x => typeof x === "string").map(x => x as Language)
    }
}